<template>
  <div v-if="useDocumentDownloader().showModal()" class="linker-wrapper">
    <div class="linker-wrapper-inner">
      <div class="sub-header-text text-center">
        <span v-if="!useDocumentDownloader().isGenerated()"
          >Getting your document ready...</span
        ><span v-else>All set! Let's go!</span>
      </div>
      <base-spinner
        v-if="!useDocumentDownloader().isGenerated()"
        large
        dark
        class="mt-2"
      />
      <template v-else
        ><base-button
          class="mt-1"
          set-width
          @click="
            goExternal(useDocumentDownloader().getFinalLink());
            useDocumentDownloader().hideDownloader();
          "
          >Download <base-icon icon="file-arrow-down"
        /></base-button>
      </template>
    </div>
  </div>
</template>
<script setup>
  const goExternal = (link) => {
    window.open(link);
    useDocumentDownloader().doDocumentMarkAsRead();
  };
</script>
