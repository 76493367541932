<template>
  <div class="container px-2 ml-auto mr-auto">
    <div
      class="p-3 mt-10 text-center bg-[#ff9800] text-gs-tarmac rounded-lg flex gap-2 items-center text-lg font-medium" 
    >
      <div class="hidden p-5 md:flex justify-center">
        <base-icon class="text-white text-3xl" />
      </div>
      <div>{{ useMaintenance().getMaintenanceMessages().warningMessage }}</div>
    </div>
  </div>
</template>
