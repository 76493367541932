<template>
  <layout-header />
  <div
    v-if="useRuntimeConfig().public.ghost === 1"
    class="container ml-auto mr-auto"
  >
    <div class="p-3 bg-yellow-100 mt-5 rounded-xl text-center">
      👻 You are currently viewing Your GoShorty in ghost mode. Nothing will be
      logged within the database. 👻
    </div>
  </div>

  <layout-maintenance-warning-bar
    v-if="useMaintenance().isMaintenanceWarning()"
  />
  <layout-maintenance-bar v-if="useMaintenance().isMaintenance()" />

  <template v-if="!useMaintenance().isMaintenanceClosed()">
    <div v-if="globalStateStore.loader">
      <div class="main-header-text">Login to view your quotes and policies</div>
      <base-form-container>
        <div class="sub-header-text">{{ globalStateStore.loaderTitle }}</div>
        <div class="mt-5 flex justify-center"><base-spinner dark large /></div>
      </base-form-container>
    </div>
    <div v-else>
      <slot></slot>
    </div>
  </template>
  <template v-else>
    <div class="mt-10"></div>
  </template>

  <navigation-pop-out-menu />
  <layout-linker-modal />
  <layout-downloader-modal />
  <layout-footer />
</template>
<script setup>
import { useSessionIdStore } from "~/stores/useSessionIdStore";
import { useDateStore } from "~/stores/useDateStore";
import { useGlobalStateStore } from "~/stores/useGlobalStateStore";
import { useMaintenanceStore } from "~/stores/useMaintenanceStore";

useHead({
  title: useRuntimeConfig().public.appName,
  link: [
    {
      rel: "apple-touch-icon",
      sizes: "57x57",
      href: "/images/appleicons/apple-touch-icon-57x57.png",
    },
    {
      rel: "apple-touch-icon",
      sizes: "60x60",
      href: "/images/appleicons/apple-touch-icon-60x60.png",
    },
    {
      rel: "apple-touch-icon",
      sizes: "72x72",
      href: "/images/appleicons/apple-touch-icon-72x72.png",
    },
    {
      rel: "apple-touch-icon",
      sizes: "76x76",
      href: "/images/appleicons/apple-touch-icon-76x76.png",
    },
    {
      rel: "apple-touch-icon",
      sizes: "114x114",
      href: "/images/appleicons/apple-touch-icon-114x114.png",
    },
    {
      rel: "apple-touch-icon",
      sizes: "120x120",
      href: "/images/appleicons/apple-touch-icon-120x120.png",
    },
    {
      rel: "apple-touch-icon",
      sizes: "144x144",
      href: "/images/appleicons/apple-touch-icon-144x144.png",
    },
    {
      rel: "apple-touch-icon",
      sizes: "152x152",
      href: "/images/appleicons/apple-touch-icon-152x152.png",
    },
    {
      rel: "apple-touch-icon",
      sizes: "180x180",
      href: "/images/appleicons/apple-touch-icon-180x180.png",
    },
  ],
  meta: [
    {
      name: "description",
      content:
        "Login to view and renew your policies. Find quotes, add drivers, download documents and buy additional temporary car, van and learner cover with Your GoShorty.",
    },
  ],
});

const sessionIdStore = useSessionIdStore();
const dateStore = useDateStore();
const globalStateStore = useGlobalStateStore();
const maintenanceStore = useMaintenanceStore();

const { getSession } = useStorage();
const { getLiveLinkHandler } = useLinker();
const { getCustomerDetails, getDetailsFromOtt } = useApi();

const closeLoader = () => {
  setTimeout(() => {
    globalStateStore.loader = false;
  }, 1500);
};

onMounted(async () => {
  getLiveLinkHandler();
  sessionIdStore.generateSessionId();
  dateStore.startLiveDateInterval();
  maintenanceStore.startMaintenanceInterval();

  let previousSession = getSession();
  const ott = useRoute().query.ott;

  if (ott) {
    const result = await getDetailsFromOtt(ott);
    if (result.success) {
      previousSession = {
        dateofbirth: result.data.record.date_of_birth,
        email: result.data.record.email,
        postcode: result.data.record.postcode,
        remember: false,
        surname: result.data.record.surname,
      };
    }
  }

  if (!previousSession) {
    globalStateStore.redirectLink = useRoute().fullPath;
    useLinker().pushLink("/");
    closeLoader();
    return;
  }

  globalStateStore.loaderTitle = "Checking details...";
  const { success } = await getCustomerDetails(previousSession);

  if (!success) {
    useLinker().pushLink("/");
    globalStateStore.hasLoginError = true;
  } else if (useRoute().path === "/") {
    useLinker().pushLink("/portal/");
  }

  closeLoader();
});

onBeforeUnmount(() => {
  dateStore.stopLiveDateInterval();
});
</script>
