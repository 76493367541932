<template>
  <div class="container px-2 ml-auto mr-auto">
    <div
      class="p-5 mt-10 text-left bg-[#ff9800] text-gs-tarmac rounded-lg flex gap-2 items-center"
    >
      <div class="hidden p-5 md:flex justify-center">
        <base-icon class="text-white text-5xl" />
      </div>
      <div class="text-gs-tarmac">
        <h2 class="text-3xl font-bold">
          {{
            useMaintenance().getMaintenanceMessages().maintenanceMessage
              .headline
          }}
        </h2>
        <div
          v-html="
            useMaintenance().getMaintenanceMessages().maintenanceMessage
              .information
          "
        >
        </div>
      </div>
    </div>
  </div>
</template>
