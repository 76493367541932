import { useMaintenanceStore } from "~/stores/useMaintenanceStore";

export default function useMaintenance() {
  const getMaintenanceMessages = () => {
    return useMaintenanceStore().getMaintenanceMessages;
  };

  const isMaintenance = () => {
    return useMaintenanceStore().isMaintenance;
  };

  const isMaintenanceClosed = () => {
    return useMaintenanceStore().isMaintenanceClosed;
  };

  const isMaintenanceWarning = () => {
    return useMaintenanceStore().isMaintenanceWarning;
  };

  return {
    getMaintenanceMessages,
    isMaintenance,
    isMaintenanceClosed,
    isMaintenanceWarning,
  };
}
